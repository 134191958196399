import React, { Component } from 'react';
import Axios from 'axios';
import downloadjs from 'downloadjs';
import './DoctorDiscussionForm.scss';
import Header from './DDGContent/Header';
import MainContent from './DDGContent/MainContent';
import SecondaryContent from './DDGContent/SecondaryContent';
import Navigation from './DDGContent/Navigation';
import $ from 'jquery';
import validator from 'validator';
import { createClient } from 'contentful';

class DoctorDiscussionForm extends Component {

  constructor(props) {
    super(props)
    this.state = {

      email: '',
      emailValid: false,
      validateEmail: false,
      TOS: false,
      CRM: false,
      enableEmailBtn: false,
      enablePrintBtn: false,
      displayValidationError: false,
      validationType: undefined,

      pdfUrl: false,
      DoctorDiscussionFormItems: null,
      submitted: false,
      thankyou: false,
      addtionalInfo: false,
      hallucinationsPerMonth: '',
      section: 'patient',
      position: -1,
      patientAnswers: {
        "formType": "Ihaveit",
        "emailAddress": "",
        "q0Ans": "false",
        "q1Ans": "false",
        "q2Ans": "false",
        "q3Ans": "false",
        "q4Ans": "false",
        "q5Ans": "false",
        "q6Ans": "false",
        "hallucinationsPerMonth": "0"
      },
      caregiverAnswers: {
        "formType": "Caregiver",
        "emailAddress": "",
        "q0Ans": "false",
        "q1Ans": "false",
        "q2Ans": "false",
        "q3Ans": "false",
        "q4Ans": "false",
        "q5Ans": "false",
        "q6Ans": "false",
        "hallucinationsPerMonth": "0"
      },
      contentfulContent: {
        intro_title: '',
        intro_paragraph: '',
        intro_footnote: '',
        intro_question: '',
        intro_button_patient: '',
        intro_button_caregiver: '',
        patient_title: '',
        patient_instructions: '',
        patient_slide_2_answer_a: '',
        patient_slide_2_answer_a: '',
        patient_slide_2_answer_c: '',
        patient_slide_2_answer_d: '',
        patient_end_title: '',
        caregiver_title: '',
        caregiver_instructions: '',
        caregiver_end_title: '',
        button_back: '',
        button_yes: '',
        button_no: '',
        slide_end_completion_message: '',
        slide_end_validate_email: '',
        slide_end_validate_email: '',
        slide_end_validate_email: '',
        slide_end_email_placeholder: '',
        slide_end_receive_updates: '',
        slide_end_terms_of_use: '',
        slide_end_email_guide: '',
        slide_end_skip_and_print: '',
        slide_thankyou_slide_reminder: '',
        slide_thankyou_slide_title: '',
        slide_thankyou_click_below: '',
        slide_thankyou_treatment_option: '',
        slide_thankyou_start_over: '',
        patientContent: [
          // Slide 2
          {
            patient_statement: "",
            images: [
              "",
              "",
              "",
            ]
          },
          // slide ???
          {
            patient_statement: "",
            images: [
              "",
              "",
              "",
            ]
          },
          // Slide 3
          {
            patient_statement: "",
            images: [
              "",
              "",
            ]
          },
          // Slide 4
          {
            patient_statement: "",
            images: [
              "",
            ]
          },
          // Slide 5
          {
            patient_statement: "",
            images: [
              "",
            ]
          },
        ],
        caregiverContent: [
          // Slide 1
          {
            caregiver_statement: '',
            images: [
              "",
              "",
              "",
            ]

          },
          // Slide 2
          {
            caregiver_statement: "",
            images: [
              "",
              "",
            ]
          },
          // Slide 3
          {
            caregiver_statement: "",
            images: [
              "",
            ]
          },
          // Slide 4
          {
            caregiver_statement: "",
            images: [
              "",
            ]
          }
        ]
      }
    }
  }

  // fetch and render content from contentful
  componentDidMount() {
    const client = createClient({
      space: 'xbztif0ye43x',
      environment: 'mtp_3.0.2_celeb-day-1-qa_migration', // defaults to 'master' if not set
      accessToken: 'l0cevDYG7n5-1jFJHiQYcpTnqJFc3m8bkZXtz8IBa4s'
    })

    client.getEntry('8PRTBDK4xOB5SWyZBOPBK')
      .then((entry) => {
        const contentfulContent = {
          intro_title: entry.fields.intro_title,
          intro_paragraph: entry.fields.intro_paragraph,
          intro_footnote: entry.fields.intro_footnote,
          intro_question: entry.fields.intro_question,
          intro_button_patient: entry.fields.intro_button_patient,
          intro_button_caregiver: entry.fields.intro_button_caregiver,
          patient_title: entry.fields.patient_title,
          patient_instructions: entry.fields.patient_instructions,
          patient_slide_2_answer_a: entry.fields.patient_slide_2_answer_a,
          patient_slide_2_answer_b: entry.fields.patient_slide_2_answer_b,
          patient_slide_2_answer_c: entry.fields.patient_slide_2_answer_c,
          patient_slide_2_answer_d: entry.fields.patient_slide_2_answer_d,
          patient_end_title: entry.fields.patient_end_title,
          caregiver_title: entry.fields.caregiver_title,
          caregiver_instructions: entry.fields.caregiver_instructions,
          caregiver_end_title: entry.fields.caregiver_end_title,
          button_back: entry.fields.button_back,
          button_yes: entry.fields.button_yes,
          button_no: entry.fields.button_no,
          slide_end_completion_message: entry.fields.slide_end_completion_message,
          slide_end_validate_email: entry.fields.slide_end_validate_email,
          slide_end_fill_out_email_doctor: entry.fields.slide_end_fill_out_email_doctor,
          slide_end_fill_out_email_pd: entry.fields.slide_end_fill_out_email_pd,
          slide_end_field_required: entry.fields.slide_end_field_required,
          slide_end_email_placeholder: entry.fields.slide_end_email_placeholder,
          slide_end_receive_updates: entry.fields.slide_end_receive_updates,
          slide_end_terms_of_use: entry.fields.slide_end_terms_of_use,
          slide_end_email_guide: entry.fields.slide_end_email_guide,
          slide_end_skip_and_print: entry.fields.slide_end_skip_and_print,
          slide_thankyou_slide_reminder: entry.fields.slide_thankyou_slide_reminder,
          slide_thankyou_slide_title: entry.fields.slide_thankyou_slide_title,
          slide_thankyou_click_below: entry.fields.slide_thankyou_click_below,
          slide_thankyou_treatment_option: entry.fields.slide_thankyou_treatment_option,
          slide_thankyou_start_over: entry.fields.slide_thankyou_start_over,
          patientContent: [
            // Slide 2
            {
              patient_statement: entry.fields.patient_slide_1_statement,
              images: [
                entry.fields.visionIcon.fields.file.url,
                entry.fields.hearingIcon.fields.file.url,
                entry.fields.feelIcon.fields.file.url,
              ]
            },
            // slide ???
            {
              patient_statement: entry.fields.patient_slide_2_statement,
              images: [
                entry.fields.visionIcon.fields.file.url,
                entry.fields.hearingIcon.fields.file.url,
                entry.fields.feelIcon.fields.file.url,
              ]
            },
            // Slide 3
            {
              patient_statement: entry.fields.patient_slide_3_statement,
              images: [
                entry.fields.harmIcon.fields.file.url,
                entry.fields.unfaithfulIcon.fields.file.url,
              ]
            },
            // Slide 4
            {
              patient_statement: entry.fields.patient_slide_4_statement,
              images: [
                entry.fields.presenceIcon.fields.file.url,
              ]
            },
            // Slide 5
            {
              patient_statement: entry.fields.patient_slide_5_statement,
              images: [
                entry.fields.houseIcon.fields.file.url,
              ]
            },
          ],
          caregiverContent: [
            // Slide 1
            {
              caregiver_statement: entry.fields.caregiver_slide_1_statement,
              images: [
                entry.fields.visionIcon.fields.file.url,
                entry.fields.hearingIcon.fields.file.url,
                entry.fields.feelIcon.fields.file.url,
              ]

            },
            // Slide 2
            {
              caregiver_statement: entry.fields.caregiver_slide_2_statement,
              images: [
                entry.fields.harmIcon.fields.file.url,
                entry.fields.unfaithfulIcon.fields.file.url,
              ]
            },
            // Slide 3
            {
              caregiver_statement: entry.fields.caregiver_slide_3_statement,
              images: [
                entry.fields.presenceIcon.fields.file.url,
              ]
            },
            // Slide 4
            {
              caregiver_statement: entry.fields.caregiver_slide_4_statement,
              images: [
                entry.fields.houseIcon.fields.file.url,
              ]
            }
          ]
        }
        this.setState({
          contentfulContent: contentfulContent
        })
      })
      .catch(console.error)
  }

  // When components re-renders, update button states
  componentDidUpdate(prevProps, prevState) {
    if (prevState.email !== this.state.email ||
      prevState.emailValid !== this.state.emailValid ||
      prevState.TOS !== this.state.TOS ||
      prevState.CRM !== this.state.CRM
    ) {
      this.updateButtonsAndValidation();
    }
  }

  // Display Validation Error
  displayValidationError = (type) => {
    this.setState({
      displayValidationError: true,
      validationType: type,
    })
  }

  // Hide Validation Error
  hideValidationError = () => {
    this.setState({
      displayValidationError: false,
      validationType: undefined
    })
  }

  // Update Email State
  setEmail = (email) => {
    this.setState({
      email: email,
      emailValid: validator.isEmail(email)
    })
    if (this.state.emailValid) {
      this.hideValidationError();
    }
  }
  // Update Email State
  setValidateEMail = () => {
    this.setState({
      validateEmail: true
    })
  }
  // Update TOS State
  setTOS = (TOS) => {
    this.setState({
      TOS: TOS
    })
  }
  // Update CRM State
  setCRM = (CRM) => {
    this.setState({
      CRM: CRM
    })
  }

  // Update Button States
  updateButtonsAndValidation = () => {
    const { email, emailValid, validateEmail, TOS, CRM, enableEmailBtn, enablePrintBtn, displayValidationError, validationType } = this.state;
    this.hideValidationError();

    // Email Empty
    if (email === '') {
      // Both Boxes Checked - Print Button Enabled
      if (TOS && CRM) {
        if (enableEmailBtn || !enablePrintBtn) {
          this.setState({
            enableEmailBtn: false,
            enablePrintBtn: true,
          })
        }
      }
      // TOS False - Print Button Disabled
      else if (!TOS && CRM) {
        if (!displayValidationError || validationType !== 'TOS') {
          this.displayValidationError('TOS');
        }
      }
      // No Boxes Checked - Print Button Enabled
      else if (!TOS && !CRM) {
        if (!enablePrintBtn || !enableEmailBtn) {
          this.setState({
            enableEmailBtn: false,
            enablePrintBtn: false,
          })
        }
      }
    }
    // Email Not Valid
    else if (!emailValid && validateEmail) {
      this.displayValidationError('notValidEmail');
    }

    // Email Valid
    //TOS True CRM True - Both Buttons Enabled
    if (TOS && CRM) {
      if (!enableEmailBtn || !enablePrintBtn) {
        this.setState({
          enableEmailBtn: true,
          enablePrintBtn: true,
        })
      }
    }
    //TOS False, CRM True - Both Buttons Disabled, Validation Error Shown
    else if (!TOS && CRM) {
      if (enableEmailBtn || enablePrintBtn || !this.displayValidationError || validationType !== 'TOS') {
        this.setState({
          enableEmailBtn: false,
          enablePrintBtn: false,
          displayValidationError: true,
          // validationType: 'TOS',
        })
      }
    }
    //TOS True CRM True - Print Buttons Enabled
    else if (!TOS && !CRM) {
      this.setState({
        enableEmailBtn: false,
        enablePrintBtn: false,
      })
    }
    //TOS True - Email/Print Button Enabled
    else if (TOS) {
      this.setState({
        enablePrintBtn: true,
        enableEmailBtn: true,
      })
    }
    //TOS False - Email Button Disabled
    else if (!TOS) {
      if (enableEmailBtn) {
        this.setState({
          enableEmailBtn: false,
        })
      }
    }


    // CRM False - disable email button
    // if (!CRM) {
    //   if (TOS) {
    //     this.setState({
    //       enableEmailBtn: false,
    //     })
    //   }
    // }


    // Email Empty or Valid
    if (TOS && CRM && displayValidationError && validationType === 'tos') {
      this.hideValidationError();
    }
  }

  // Generate PDF - Email or Print

  generatePDF = async (buttonType) => {

    const reactState = this;
    let jsonData = {};
    let url = "/";

    jsonData = this.state.section === 'patient' ? this.state.patientAnswers : this.state.caregiverAnswers;

    // Email is Invalid 
    if (!this.state.emailValid) {
      // Show Email Validation Error
      if (buttonType === 'email') {
        this.displayValidationError('email');
        return;
      }
      // Show Print Validation Error (if CRM is true)
      else if (buttonType === 'print' && this.state.CRM) {
        this.displayValidationError('print');
        return;
      }
    }
    else {
      this.hideValidationError();
      this.setState({
        enableEmailBtn: false,
        enablePrintBtn: false,
      })
    }

    // Set JSON Data

    jsonData['hallucinationsPerMonth'] = this.state.hallucinationsPerMonth;
    jsonData['emailAddress'] = this.state.email;
    jsonData['CRMSignUp'] = this.state.CRM;

    if (buttonType === 'email') {
      jsonData['sendEmail'] = true;
      // If Email Selected, CRM Always True
      jsonData['CRMSignUp'] = true;
    }
    if (buttonType === 'print') {
      // If Print Selected, Do Not Send Email
      jsonData['sendEmail'] = false;
    }

    // add caregiver/patient to the json
    if (this.state.section === "caregiver") {
      jsonData['iamacaregiver'] = true;
      jsonData['iamlivingwithPD'] = false;
    }
    else {
      jsonData['iamacaregiver'] = false;
      jsonData['iamlivingwithPD'] = true;
    }

    $('#ddg-container .buttons').hide();
    $('#ddg-container nav').hide();

    if (process.env.NODE_ENV === "development") {
      url = "http://localhost:8000/"
    }

    try {
      await Axios({
        method: 'post',
        url: url + "api/generate_pdf",
        data: jsonData,
        responseType: 'blob',
        headers: {
          'Content-type': 'application/json'
        }
      })
        .then(function (res) {
          const hash = Math.random().toString(36).substring(2, 8);
          const filename = `DoctorDiscussionForm__${hash}.pdf`;

          reactState.setState({
            ...reactState.state,
            pdfUrl: url + res.data.file,
            submitted: true
          });
          if (res.data && res.data.size > 0) {
            if (!jsonData['sendEmail']) {
              downloadjs(res.data, filename, "application/pdf");
            }
          }
        });
    }
    catch (errors) {
      console.log("errors", errors)
    }

    this.setState({
      ...this.state,
      CRM: false,
      TOS: false,
      thankyou: true,
      displayValidationError: false,
      enableEmailBtn: false,
      submitted: true,
      emailValid: false,
      email: '',

    })

    return true;
  }

  changePosition = (direction, qAnswer) => {
    if (this.state.section === 'patient') {
      const newPatientAnswer = { ...this.state.patientAnswers }
      newPatientAnswer['q' + this.state.position + 'Ans'] = qAnswer;
      this.setState((prevState) => {
        return {
          ...prevState,
          patientAnswers: { ...newPatientAnswer }
        }
      });
    } else {
      const caregiverAnswers = { ...this.state.caregiverAnswers }
      caregiverAnswers['q' + this.state.position + 'Ans'] = qAnswer
      this.setState((prevState) => {
        return {
          ...prevState,
          caregiverAnswers: { ...caregiverAnswers }
        }
      });
    }
    this.setState({
      position: direction === "forward"
        ? this.state.position + 1
        : this.state.position - 1
    });

  }

  startOver = () => {
    $('#ddg-container .buttons').show();
    $('#ddg-container nav').show();
    this.setState({
      position: - 1
    });
    this.setState({
      thankyou: false,
      addtionalInfo: false
    })

  }

  setSection = (name) => {
    this.setState({
      section: name === 'patient' ? 'patient' : 'caregiver',
      position: this.state.position + 1
    })
  }

  setHallucinationsPerMonth = (numHal) => {
    this.setState((prevState) => {
      return { ...prevState, hallucinationsPerMonth: numHal }
    })
  }

  render() {

    const section = this.state.section;
    const position = this.state.position;
    const patientAnswers = this.state.patientAnswers;
    const caregiverAnswers = this.state.caregiverAnswers;
    const content = section === "patient" ? this.state.contentfulContent.patientContent : this.state.contentfulContent.caregiverContent;
    const endOfShow = position === content.length;
    const thankyou = this.state.thankyou;

    const classForLastPages = () => {
      if (endOfShow && thankyou) return ' ';
      if (endOfShow && !thankyou) return 'flex-lg-row';
      return 'flex-lg-row';
    }

    return (
      <div id="ddg-container" className='container d-flex flex-column' screen={position} type={this.state.section}>
        <Header
          position={position} section={section}
          changePosition={(direction, qAnswer) => this.changePosition(direction, qAnswer)}
          thankyou={thankyou}
          contentfulContent={this.state.contentfulContent}
        />
        <div className={`
          ddg-content 
          row flex-column 
          ${classForLastPages()}
          justify-content-center 
          align-items-center`
        }>
          <MainContent
            content={content[position]}
            section={section}
            position={position}
            setEmail={(email, startValidating) => {
              if (startValidating) this.setValidateEMail();
              this.setEmail(email)
            }
            }
            setTOS={(TOS) => this.setTOS(TOS)}
            setCRM={(CRM) => this.setCRM(CRM)}
            thankyou={thankyou}
            endOfShow={endOfShow}
            displayValidationError={this.state.displayValidationError}
            TOS={this.state.TOS}
            CRM={this.state.CRM}
            validationType={this.state.validationType}
            emailValid={this.state.emailValid}
            contentfulContent={this.state.contentfulContent}
          />
          <SecondaryContent
            content={content[position]}
            patientAnswers={patientAnswers}
            caregiverAnswers={caregiverAnswers}
            position={position}
            section={section}
            thankyou={thankyou}
            endOfShow={endOfShow}
            enableEmailBtn={this.state.enableEmailBtn}
            enablePrintBtn={this.state.enablePrintBtn}
            startOver={() => this.startOver()}
            setSection={(section) => this.setSection(section)}
            changePosition={(direction, qAnswer) => this.changePosition(direction, qAnswer)}
            generatePDF={(buttonType) => this.generatePDF(buttonType)}
            hallucinationsPerMonth={(hallucinationsPerMonth) => this.setHallucinationsPerMonth(hallucinationsPerMonth)}
            contentfulContent={this.state.contentfulContent}
          />
          <Navigation
            position={position}
            length={content.length}
          />
        </div>
      </div>
    );
  }
}

export default DoctorDiscussionForm;