import React, { Component, Fragment } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Helmet } from "react-helmet";
import DoctorDiscussionForm from './components/DoctorDiscussionForm';
import PasswordProtectedForm from './components/PasswordProtectedForm';
//import DoctorResourceGuideDRPForm from './components/DoctorResourceGuideDRPForm';
//import SignupForm from './components/SignupForm';
// import UnsubscribeForm from './components/UnsubscribeForm';
// window.$ = window.jQuery = require('jquery');

class App extends Component {
  render() {
    const pathName = window.pathName;
    return ( 
        <Fragment >
            <Helmet>
                <title > Acadia LTC </title>
            </Helmet>
            <BrowserRouter>
                <Switch>
                    <Route location={pathName} exact path="/" component={DoctorDiscussionForm} /> 
                    <Route location={pathName} exact path="/pw-protection" component={PasswordProtectedForm} />

                    {/*<Route location={pathName} exact path = "/discussion-guide-drp" component={DoctorResourceGuideDRPForm} />
                    <Route location={pathName} exact path="/sign-up" component={SignupForm} /> 
                    <Route location={pathName} exact path="/unsubscribe" component={UnsubscribeForm} /> */}
                </Switch> 
            </BrowserRouter>
        </Fragment>
    );
  }
}

export default App;
