import React from "react";

const Errors = (props) => {
  if (props.show) {
    if (props.type === "notValidEmail") {
      return (
        <span className="error email">
          {props.contentfulContent.slide_end_validate_email}
        </span>
      );
    }
    if (props.type === "email") {
      return (
        <span className="error email">
          {props.contentfulContent.slide_end_fill_out_email_doctor}
        </span>
      );
    }
    if (props.type === "print") {
      return (
        <span className="error print">
          {props.contentfulContent.slide_end_fill_out_email_pd}
        </span>
      );
    }
    return null;
  } else return null;
};

const TOSError = (props) => {
  if (props.show) {
    if (!props.type) {
      return (
        <span className="error print">
          {props.contentfulContent.slide_end_field_required}
        </span>
      );
    }
    return null;
  } else return null;
};

const hasEmailErrors = (errorType) => {
  const emailErrors = ["notValidEmail", "email"];
  return emailErrors.includes(errorType);
}

const hasTOSError = (props) => {
  return props.show && !props.type;
}

const MainContent = (props) => {
  const returnTopContent = () => {
    if (props.position === -1) {
      return (
        <>
          <h1>{props.contentfulContent.intro_title}</h1>
          <p dangerouslySetInnerHTML={{ __html: props.contentfulContent.intro_paragraph }}></p>
        </>
      );
    }
    if (props.endOfShow && !props.thankyou) {
      return (
        <>
          <h1>{props.section === "patient" ? props.contentfulContent.patient_end_title : props.contentfulContent.caregiver_end_title}</h1>
          {returnPdfEmailForm()}
        </>
      );
    }
    if (props.endOfShow && props.thankyou) {
      return thankyouSlide();
    }
    if (props.section === "patient") {
      return (
        <>
          <h1>{props.contentfulContent.patient_title}</h1>
          <p>{props.contentfulContent.patient_instructions}</p>
        </>
      );
    }
    if (props.section === "caregiver") {
      return (
        <>
          <h1>{props.contentfulContent.caregiver_title}</h1>
          <p>{props.contentfulContent.caregiver_instructions}</p>
        </>
      );
    }
  };

  // pdf or email form Content
  const returnPdfEmailForm = () => {
    const handleSubmit = (e) => {
      e.preventDefault();
      // ???
    };

    const handleTOSCheckbox = (event) => {
      props.setTOS(event.target.checked);
    };

    const handleCRMCheckbox = (event) => {
      props.setCRM(event.target.checked);
    };

    return (
      <>
        <p className="ddg-complete">
          {props.contentfulContent.slide_end_completion_message}
        </p>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="form-group">
            <div className="ddg-email">
              <input
                id="ddg-email"
                className={`form-control ${hasEmailErrors(props.validationType) && 'error-border'}`}
                name="email"
                type="text"
                placeholder={props.contentfulContent.slide_end_email_placeholder}
                onChange={(e) => props.setEmail(e.currentTarget.value)}
                onInput={(e) => props.setEmail(e.currentTarget.value)}
                onBlur={(e) => props.setEmail(e.currentTarget.value, true)}
                autoFocus
              />
              <Errors
                show={props.displayValidationError}
                type={props.validationType}
                contentfulContent={props.contentfulContent}
              />
            </div>
            <div className={`ddg-tos ${props.displayValidationError && props.CRM &&!props.TOS &&  'dark-red'}`}>
              <label className="inline-checkboxes tos">
                <input
                  type="checkbox"
                  name="agreement"
                  className="tos-checkbox"
                  onChange={handleTOSCheckbox}
                />{""}
                <span className={`${hasTOSError({show: props.displayValidationError, type: props.TOS}) && 'error'}`} dangerouslySetInnerHTML={{__html: props.contentfulContent.slide_end_terms_of_use}}></span>
              </label>
              <TOSError
                  show={props.displayValidationError}
                  type={props.TOS}
                  contentfulContent={props.contentfulContent}
                />
            </div>
            {/*
            <div className="ddg-suscribe">
              <label className="inline-checkboxes receive-updates">
                <input
                  type="checkbox"
                  name="receive-updates"
                  className="tos-checkbox"
                  onChange={handleCRMCheckbox}
                />{""}
                <span dangerouslySetInnerHTML={{ __html: props.contentfulContent.slide_end_receive_updates }}></span>
              </label>
            </div>
             */}
          </div>
        </form>
        <div className="medium-spacer"></div>
      </>
    );
  };

  const thankyouSlide = () => {
    return (
      <>
        <div id="thank-you-content" className="d-flex flex-column justify-content-center align-items-center">
          <h1>{props.contentfulContent.slide_thankyou_slide_title}</h1>
          <p className="text-center mb-4">{props.contentfulContent.slide_thankyou_slide_reminder}</p>
          <p className="text-center">{props.contentfulContent.slide_thankyou_click_below}</p>
          <div className="large-spacer"></div>
        </div>
      </>
    );
  };

  const thankyouContainer = () => {
    const position = props.position + 1;
    return (
      <div className={"ddg-main-content col-12 justify-content-center align-items-center last-screen screen" + position}>
        <div className="top-copy">{thankyouSlide()}</div>
      </div>
    )
  }

  const returnPdfEmailFormContainer = () => {
    const position = props.position + 1;
    return (
      <div className={"ddg-main-content col-12 col-lg-5 last-screen screen" + position}>
        <div className="top-copy">{returnTopContent()}</div>
      </div>
    )
  }

  // Render component
  if (props.endOfShow) {
    return (
      <>
        {props.thankyou === true ? thankyouContainer() : returnPdfEmailFormContainer()}
      </>
    );
  } else {
    const position = props.position + 1;
    return (
      <>
        <div
          className={
            "ddg-main-content col-12 col-lg-5 content screen" + position
          }
        >
          <div className="top-copy">
            {returnTopContent()}
            <p className="warning-save-info">
              {props.contentfulContent.intro_footnote}
            </p>
          </div>
        </div>
      </>
    );
  }
};

export default MainContent;
