import React, { Component } from 'react';
import Axios from 'axios';
import { connect } from 'react-redux';
import * as actions from '../actions';

class PasswordProtectedForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            submitted: false,
            error: null,
        };
    }

    // Handle form submission
    onSubmit = async (event) => {
        event.preventDefault(); // Prevent form default submission behavior

        const { password } = this.state;

        // Check if password is empty BEFORE making the API call
        if (!password.trim()) { 
            this.setState({ error: 'Please enter a password.' });
            return; // Stop further execution
        }
        
        const values = { password }; // Only send password to the backend

        // Set the correct URL based on the environment
        let url = process.env.NODE_ENV === "development" ? "http://localhost:8000/" : "/";

        try {
            const response = await Axios({
                method: 'post',
                url: url + "api/validate_password",
                data: new URLSearchParams(values).toString(),
                headers: {
                    'Content-type': 'application/x-www-form-urlencoded',
                },
            });

            // Password correct, can proceed to display video
            if (response.data.success) {
                // Update the content inside the container to remove the form and show the video
                document.getElementById('video_content').innerHTML = `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/1067417669?h=3fcf53533f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="PDP-US-0047 Celeb Campaign HCP Motivational Video"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>`;
            } else {
                this.setState({ error: 'Incorrect password. Please try again.' }); // Set error state
            }
        } catch (error) {
            console.error('Error:', error); // Log the error for debugging
            this.setState({ error: 'Incorrect password. Please try again.' }); // Set error state
        }

        // Scroll to the top after the form submission (if needed)
        this.scrollToTop();
    };

    // Scroll to the top of the page (could be a helper function)
    scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    // Update password state as user types
    handlePasswordChange = (event) => {
        this.setState({ password: event.target.value });
    };

    render() {
        const { password, submitted, error } = this.state;

        return (
            <>
                <h2>Please enter your password</h2>
                <form className="container col-md-6" onSubmit={this.onSubmit}>
                    <input
                        type="password"
                        className="form-control mb-xs-4"
                        id="pwd_video"
                        value={password}
                        onChange={this.handlePasswordChange} // Update password state on input change
                        placeholder="Enter your password"
                    />

                    <button className="ddg-button" type="submit">
                        Enter
                    </button>
                </form>
                <div class="container col-md-12">
                    {submitted && (
                        <div className="alert alert-success mt-3">
                            Password validated successfully!
                        </div>
                    )}

                    {error && (
                        <div className="validation-error">
                            {error}
                        </div>
                    )}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    // If you have any Redux states you want to map, you can do so here
});

export default connect(mapStateToProps, actions)(PasswordProtectedForm);