import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import * as serviceWorker from './serviceWorker';
import App from './App';
import DoctorDiscussionForm from './components/DoctorDiscussionForm';
//import DoctorResourceGuideDRPForm from './components/DoctorResourceGuideDRPForm';
import SignupForm from './components/SignupForm';
//import UnsubscribeForm from './components/UnsubscribeForm';
//import UnsubscribeDRPForm from './components/UnsubscribeDRPForm';
import PasswordProtectedForm from './components/PasswordProtectedForm';

// Reducers
import reducers from './reducers';

// Styling
import "./App.css";
import "./lentils.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

// Only run devtools in dev
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(
  applyMiddleware(reduxThunk)
);

// Redux
const store = createStore(
  reducers,
  enhancer
);

//console.log("process.env.NODE_ENV", process.env.NODE_ENV);

if (process.env.NODE_ENV !== 'development') {

  if (document.getElementById('discussion-guide')) {
    ReactDOM.render(
      <React.StrictMode>
        <Provider store={store}>
          <DoctorDiscussionForm />
        </Provider>
      </React.StrictMode>,
      document.getElementById('discussion-guide')
    );
  }

  if (document.getElementById('video_content')) {
    ReactDOM.render(
      <React.StrictMode>
        <Provider store={store}>
          <PasswordProtectedForm />
        </Provider>
      </React.StrictMode>,
      document.getElementById('video_content')
    );
  }


  if (document.getElementById('sign-up-form')) {
    ReactDOM.render(
      <React.StrictMode>
        <Provider store= {store}>
          <SignupForm />
        </Provider>
      </React.StrictMode>,
      document.getElementById('sign-up-form')
    );
  }

  if (document.getElementById('sign-up-pdp-form')) {
    ReactDOM.render(
      <React.StrictMode>
        <Provider store= {store}>
          <SignupForm />
        </Provider>
      </React.StrictMode>,
      document.getElementById('sign-up-pdp-form')
    );
  }

  /*if (document.getElementById('unsubscribe-form')) {
    ReactDOM.render(
      <React.StrictMode>
        <Provider store= {store}>
          <UnsubscribeForm />
        </Provider>
      </React.StrictMode>,
      document.getElementById('unsubscribe-form')
    );
  }

  if (document.getElementById('unsubscribe-drp-form')) {
    ReactDOM.render(
      <React.StrictMode>
        <Provider store= {store}>
          <UnsubscribeDRPForm />
        </Provider>
      </React.StrictMode>,
      document.getElementById('unsubscribe-drp-form')
    );
  }
  */

} else {
  // Dev mode allows us to work on all pages easily with react router
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
          <App />
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();